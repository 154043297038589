<template>
  <div class="dialog_container">
    <el-form  class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
      <div class="row" style="border-bottom: 1px solid #BFBFCD;margin-bottom: 12px;">
        <el-form-item :label="$t('Label.Owner')" prop="ownerSectionIds">
          <el-select v-model="searchForm.ownerSectionIds" class="section" :class="{multiple_selected: multipleSelect(searchForm.ownerSectionIds)}" placeholder="Select" multiple collapse-tags clearable>
            <el-option
              v-for="section in ownerSectionLists"
              :key="section.sectionId"
              :label="section.sectionShortName"
              :value="section.sectionId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
          <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
        </el-form-item>
        <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
          <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
        </el-form-item>
        <el-form-item :label="$t('Label.Goods')" prop="processGoodsName">
          <el-input type="text" v-model="searchForm.processGoodsName" placeholder="Enter keyword"></el-input>
        </el-form-item>
        <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
          <img src="@/assets/images/icons/view_all.svg">
        </tw-button>
      </div>

      <transition
        name="content"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div v-show="openForm">
          <div class="row">
            <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
              <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
              <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
              <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
              <el-input type="text" v-model="searchForm.invoiceNo" placeholder="Enter keyword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Contract Date')" prop="contractDate">
              <el-date-picker
                v-model="searchForm.contractDate"
                type="daterange"
                range-separator="to"
                format="yyyy-MM-dd"
                :default-value="defaultDate"
                @change.native="onDateRangeChange"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('Label.Seller')" prop="sellerName">
              <el-input type="text" v-model="searchForm.sellerName" placeholder="Enter keyword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Buyer')" prop="buyerName">
              <el-input type="text" v-model="searchForm.buyerName" placeholder="Enter keyword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Main Goods')" prop="goodsName">
              <el-input type="text" v-model="searchForm.goodsName" placeholder="Enter keyword"></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('Label.Status')" prop="tradingProgressStatus" style="margin-right: 40px">
              <el-select v-model="searchForm.tradingProgressStatus" placeholder="Select" clearable>
                <el-option
                  v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                  :key="status.code"
                  :label="status.label"
                  :value="status.code">
                </el-option>
              </el-select>
            </el-form-item> -->
          </div>
        </div>
      </transition>

      <div class="row drop space_between">
        <div>
          <tw-button
            type="secondary"
            size="medium"
            icon="search"
            @click="search"
          >
            Search
          </tw-button>
          <tw-button
            type="default"
            size="medium"
            @click="clear"
            style="margin: 8px 16px 16px;"
          >
            Clear
          </tw-button>
        </div>
      </div>
    </el-form>

    <tw-table-system ref="tableSystem" :height="400" :schemas="schemas" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" :isInitialSearch="isInitialSearch" :rowKey="getRowKey" @show-flow-detail="showFlowDetail" @paging="onPaging"  @selection-change="selectionChange" />
    <div style="display: flex;justify-content: flex-end;padding-top: 12px">
      <tw-button type="primary" :disabled="!selectFlows.length"  @click="register">OK</tw-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { TRADING_SEARCH_SORT_TARGET,DEFAULT_PAGENATION, API_USE_CODE } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/tradingListSchema.json';

export default {
  name: 'TwSelectFlowForCombine',
  mixins: [mixinEntityList, collapseTransition],
  props: {
    flows: Array,
  },
  data() {
    return {
      activeTab: 0,
      schemas: [],
      items: null,
      addTradingResponse: null,
      searchForm: {
        ownerSectionIds: [],
        fromSectionIds: [],
        toSectionIds: [],
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        invoiceNo: '',
        contractDate: '',
        sellerName: '',
        buyerName: '',
        goodsName: '',
        tradingProgressStatus: null,
        processGoodsName: '',
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      prevData: null,
      // 初期検索フラグをemptyMessageの出し分けに使う
      isInitialSearch: 0,
      forbidden: false, // 認可エラーフラグ
      selection: true,
      selectFlows: [],
    };
  },
  computed: {
    ownerSectionLists() {
      return this.$store.getters.getOwnerSectionLists;
    },
    drawerShow() {
      return this.$store.state.drawerShow
    },
    isPic() {
      // ユーザーロルが担当者か
      return this.$store.getters.isPic;
    },
    isShipper() {
      // カンパニーロールが荷主か
      return this.$store.getters.isShipper;
    },
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Trading List')},
      ];
    },
  },
  created() {
    schemas.forEach(s => {
      if (s.key === 'tradingManageName') {
        s.dataType = 'FlowDetail';
      }
    });
    this.schemas = schemas;

    const queries = this.$store.getters.getQueries('OTHER_TRADING_LIST');
    if (queries) {
      this.searchForm = queries;
    }

    this.fetch();
  },
  methods: {
    // POSTパラメータを生成しますs
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        ownerSectionIds: f.ownerSectionIds,
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        flowName: f.flowName,
        invoiceNo: f.invoiceNo,
        contractDateStartDate: this.getYMDStartDate(f.contractDate),
        contractEndDate: this.getYMDEndDate(f.contractDate),
        goodsName: f.goodsName,
        sellerName: f.sellerName,
        buyerName: f.buyerName,
        processGoodsName: f.processGoodsName,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
        apiUseCode: API_USE_CODE.OTHER_TRADING_LIST,
        flows: this.flows,
      }
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_td_2 別取引フロー検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/otherTradingFlowSearch',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = this.flatten(res.tradingFlowList, 'linkageInfo');
        if (!_.isEmpty(res.ownerSectionLists)) {
          this.$store.commit('SET_OWNER_SECTION_LISTS', _.map(res.ownerSectionLists, s => {
            return {
              ...s,
              label: s.sectionShortName,
              value: s.sectionId,
            }
          }));
        }
        this.totalCount = res.totalCount;
        this.isInitialSearch = this.lastQuery.initialFlag;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        if (err.statusCode === 403) {
          this.forbidden = true;
        }
        this.items = [];
        this.isInitialSearch = this.lastQuery.initialFlag;
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      if (this.items && this.items.length) {
        this.$refs.tableSystem.clearSelection();
      }
      this.items = null;
      this.selectFlows = [];
      this.offset = 0;
      this.$store.commit('SET_QUERIES', {key: 'OTHER_TRADING_LIST', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: 'OTHER_TRADING_LIST', queries: _.cloneDeep(this.searchForm)});
    },
    // 新規取引追加ドロワー表示
    addTrading() {
      this.$store.commit('SET_DRAWER', true);
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return {..._.omit(o, [target]), ...o.linkageInfo};
      });
    },
    showFlowDetail(row) {
      this.$emit('show-flow-detail', row);
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [TRADING_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // テーブル行のキーを返却します
    getRowKey(row) {
      return `${row.tradingId}-${row.tradingFlowId}`;
    },
    selectionChange(multipleSelection) {
      this.selectFlows = multipleSelection.map(f => f.tradingId + '-' + f.tradingFlowId);
    },
    register() {
      this.$emit('add-flows', this.selectFlows);
    },
  },
};
</script>

<style lang="scss">
.el-dialog .dialog_container {
  .search_unit.compact .el-select .el-input__inner, .search_unit.compact .el-cascader .el-input__inner {
    width: 235px;
  }
}

</style>
